import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../redux/store";
import { setCategoryId } from "../redux/filter/slice";

//import { useWhyDidYouUpdate } from "ahooks";

const Categories: React.FC = React.memo(() => {
  const categoryId = useSelector(
    (state: RootState) => state.filters.categoryId
  );
  const dispatch = useDispatch();

  const categories: string[] = [
    "All",
    "Meat",
    "Vegetarian",
    "BBQ",
    "Spicy",
    "Closed",
  ];

  //useWhyDidYouUpdate("Categories", { setCategoryId, categoryId });
  return (
    <div className="categories">
      <ul>
        {categories.map((value, index) => {
          return (
            <li
              onClick={() => dispatch(setCategoryId(index))}
              className={categoryId === index ? "active" : ""}
              key={index}
            >
              {value}
            </li>
          );
        })}
      </ul>
    </div>
  );
});

export default Categories;
