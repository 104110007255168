import { createAsyncThunk } from "@reduxjs/toolkit";
import { PizzaBlockPropsType } from "../../components/PizzaBlock/PizzaBlock";
import axios from "axios";

export const getPizzas = createAsyncThunk(
  "pizzas/getPizzas",
  async (url: string) => {
    const { data } = await axios.get<PizzaBlockPropsType[]>(url);
    return data as PizzaBlockPropsType[];
  }
);
