import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { RootState } from "../../redux/store";
import { CartItemType } from "../../redux/cart/types";
import { addItem, calcTotal } from "../../redux/cart/slice";

export type PizzaBlockPropsType = {
  id: string;
  name: string;
  price: number;
  imageUrl: string;
  sizes: number[];
  types: number[];
};

const PizzaBlock: React.FC<PizzaBlockPropsType> = ({
  id,
  name,
  price,
  imageUrl,
  sizes,
  types,
}) => {
  const targetObj = useSelector((state: RootState) =>
    state.cart.items.find((obj: CartItemType) => obj.id === id)
  );
  const dispatch = useDispatch();

  const typesName = ["thin", "traditional"];
  const [activeType, setActiveType] = React.useState(0);
  const [activeSize, setActiveSize] = React.useState(0);

  const addTo = () => {
    const item: CartItemType = {
      id,
      name,
      price,
      imageUrl,
      size: sizes[activeSize],
      type: typesName[activeType],
      count: 0,
    };
    dispatch(addItem(item));
    dispatch(calcTotal(""));
  };

  return (
    <div className="pizza-block">
      <Link to={`/pizza/${id}`}>
        <img className="pizza-block__image" src={imageUrl} alt={name} />
      </Link>
      <h4 className="pizza-block__title">{name}</h4>
      <div className="pizza-block__selector">
        <ul>
          {types.map((value, i) => {
            return (
              <li
                onClick={() => setActiveType(i)}
                className={activeType === i ? "active" : ""}
                key={i}
              >
                {typesName[value]}
              </li>
            );
          })}
        </ul>
        <ul>
          {sizes.map((value, i) => (
            <li
              onClick={() => setActiveSize(i)}
              className={activeSize === i ? "active" : ""}
              key={i}
            >
              {value} cm
            </li>
          ))}
        </ul>
      </div>
      <div className="pizza-block__bottom">
        <div className="pizza-block__price">from {price} £</div>
        <button
          onClick={() => addTo()}
          className="button button--outline button--add"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.8 4.8H7.2V1.2C7.2 0.5373 6.6627 0 6 0C5.3373 0 4.8 0.5373 4.8 1.2V4.8H1.2C0.5373 4.8 0 5.3373 0 6C0 6.6627 0.5373 7.2 1.2 7.2H4.8V10.8C4.8 11.4627 5.3373 12 6 12C6.6627 12 7.2 11.4627 7.2 10.8V7.2H10.8C11.4627 7.2 12 6.6627 12 6C12 5.3373 11.4627 4.8 10.8 4.8Z"
              fill="white"
            />
          </svg>
          <span>Add</span>
          <i>{!targetObj ? 0 : targetObj.count}</i>
        </button>
      </div>
    </div>
  );
};

export default PizzaBlock;
