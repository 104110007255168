import React from "react";
import ContentLoader from "react-content-loader";

const PizzaBlockSkeleton: React.FC = () => (
  <ContentLoader
    speed={2}
    width={280}
    height={466}
    viewBox="0 0 280 466"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <circle cx="130" cy="130" r="130" />
    <rect x="0" y="277" rx="5" ry="5" width="280" height="27" />
    <rect x="0" y="322" rx="5" ry="5" width="280" height="88" />
    <rect x="0" y="435" rx="5" ry="5" width="90" height="27" />
    <rect x="168" y="430" rx="5" ry="5" width="108" height="45" />
  </ContentLoader>
);

export default PizzaBlockSkeleton;
