import React from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { Button, ThemeProvider } from "@mui/material";
import { theme } from "../components/Pagination/Pagination";

const FullPizza: React.FC = () => {
  const [pizza, setPizza] = React.useState<{
    id: number;
    imageUrl: string;
    name: string;
    price: number;
    rating: number;
  }>();
  const { id } = useParams();

  React.useEffect(() => {
    async function fetchPizza() {
      try {
        const { data } = await axios.get(
          `https://64ba69c35e0670a501d62006.mockapi.io/items/${id}`
        );
        setPizza(data);
      } catch (error) {
        console.log(error);
      }
    }

    fetchPizza();
  }, []);

  if (!pizza) {
    return <div>Loading ...</div>;
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        <div>
          <p>FullPizza</p>
          <div>{pizza.id}</div>
          <div>{pizza.imageUrl}</div>
          <div>{pizza.name}</div>
          <div>{pizza.price}</div>
          <div>{pizza.rating}</div>
        </div>
        <Link to="/">
          <Button variant="contained" color="primary">
            Back
          </Button>
        </Link>
      </ThemeProvider>
    </>
  );
};

export default FullPizza;
