import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FilterSliceStateType, SortType } from "./types";

const initialState: FilterSliceStateType = {
  searchValue: "",
  categoryId: 0,
  selectedPage: 1,
  sortObj: {
    name: "popularity",
    querry: "rating",
    id: 0,
  },
};

export const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setCategoryId: (state, action: PayloadAction<number>) => {
      state.categoryId = action.payload;
    },
    setSortObj: (state, action: PayloadAction<SortType>) => {
      state.sortObj = action.payload;
    },
    setSelectedPage: (state, action: PayloadAction<number>) => {
      state.selectedPage = action.payload;
    },
    setFilters: (state, action: PayloadAction<FilterSliceStateType>) => {
      state.categoryId = Number(action.payload.categoryId);
      state.selectedPage = Number(action.payload.selectedPage);
      state.sortObj = action.payload.sortObj;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCategoryId,
  setSortObj,
  setSelectedPage,
  setFilters,
  setSearchValue,
} = filterSlice.actions;

export default filterSlice.reducer;
