import React from "react";

import NotFoundBlock from "../components/NotFoundBlock/NotFoundBlock";

const NotFound: React.FC = () => {
  return (
    <div>
      <h2> This is a H2 header</h2>
      <NotFoundBlock />
      <button>This is a button</button>
    </div>
  );
};

export default NotFound;
