import { createSlice } from "@reduxjs/toolkit";
import { PizzasSliceStateType } from "./types";
import { getPizzas } from "./asyncActions";

const initialState: PizzasSliceStateType = {
  items: [],
  isLoading: true,
};

export const pizzasSlice = createSlice({
  name: "pizzas",
  initialState,
  reducers: {
    setItem: (state, action) => {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPizzas.pending, (state, action) => {
      //console.log("pending");
      state.isLoading = true;
      state.items = [];
    });
    builder.addCase(getPizzas.fulfilled, (state, action) => {
      //console.log("fulfilled");
      state.items = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getPizzas.rejected, (state, action) => {
      //console.log("rejected");
      state.items = [];
    });
  },
});

export const { setItem } = pizzasSlice.actions;

export default pizzasSlice.reducer;
