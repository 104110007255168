import { CartItemType } from "../redux/cart/types";

const data = localStorage.getItem("cart");

export const getCartFromLS = () => {
  return data ? JSON.parse(data) : [];
};

export const getTotalFromLS = () => {
  const cart: CartItemType[] = getCartFromLS();
  return cart.reduce((acc: number, obj: CartItemType) => {
    return acc + obj.price * obj.count;
  }, 0);
};

export const getCountFromLS = () => {
  const cart: CartItemType[] = getCartFromLS();
  return cart.reduce((acc: number, obj: CartItemType) => {
    return acc + obj.count;
  }, 0);
};
