import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  getCartFromLS,
  getCountFromLS,
  getTotalFromLS,
} from "../../utils/getCartFromLS";
import { CartItemType, CartSliceStateType, RemoveItemType } from "./types";

const initialState: CartSliceStateType = {
  countTotal: getCountFromLS(),
  totalPrice: getTotalFromLS(),
  items: getCartFromLS(),
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state, action: PayloadAction<CartItemType>) => {
      let foundItem = state.items.find((obj) => {
        return obj.id === action.payload.id;
      });

      if (!foundItem) {
        state.items.push({
          ...action.payload,
          count: 1,
        });
      } else {
        foundItem.count++;
      }
    },
    removeItem: (state, action: PayloadAction<RemoveItemType>) => {
      if (action.payload.action === "one") {
        let foundItem = state.items.find((obj) => {
          return obj.id === action.payload.id;
        });
        if (foundItem && foundItem.count > 1) {
          foundItem.count--;
        }
      } else {
        state.items = state.items.filter((obj) => {
          return obj.id !== action.payload.id;
        });
      }
    },
    clearItems: (state, action) => {
      state.items = [];
    },
    calcTotal: (state, action) => {
      state.totalPrice = state.items.reduce((acc, obj) => {
        return acc + obj.price * obj.count;
      }, 0);
      state.countTotal = state.items.reduce((acc, obj) => {
        return acc + obj.count;
      }, 0);
    },
  },
});

export const { addItem, removeItem, clearItems, calcTotal } = cartSlice.actions;

export default cartSlice.reducer;
