import React from "react";
import {
  Pagination as Pag,
  createTheme,
  colors,
  ThemeProvider,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { setSelectedPage } from "../../redux/filter/slice";

//import styles from "./Pagination.module.scss";

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.orange[800],
    },
  },
});

const Pagination: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={theme}>
      <Pag
        count={3}
        defaultPage={1}
        color="primary"
        showFirstButton
        showLastButton
        onChange={(event, page) => {
          dispatch(setSelectedPage(page));
        }}
      />
    </ThemeProvider>
  );
};

export default Pagination;
