import { Routes, Route } from "react-router-dom";
import React from "react";

import "./scss/app.scss";

import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
//import Cart from "./pages/Cart";
import FullPizza from "./pages/FullPizza";
import MainTemplate from "./templates/MainTemplate";

const Cart = React.lazy(
  () => import(/* webpackChunkName: "Cart" */ "./pages/Cart")
);

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainTemplate />}>
        <Route path="" element={<Home />} />
        <Route
          path="cart"
          element={
            <React.Suspense fallback={<div>Loading ...</div>}>
              <Cart />
            </React.Suspense>
          }
        />
        <Route path="*" element={<NotFound />} />
        <Route path="pizza/:id" element={<FullPizza />} />
      </Route>
    </Routes>
  );
}

export default App;
