import React from "react";

import qs from "qs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Categories from "../components/Categories";
import Sort from "../components/Sort";
import PizzaBlock from "../components/PizzaBlock/PizzaBlock";
import PizzaBlockSkeleton from "../components/PizzaBlock/PizzaBlockSkeleton";
import Pagination from "../components/Pagination/Pagination";

import { list } from "../components/Sort";
import { RootState, useAppDispatch } from "../redux/store";
import { filterSelector } from "../redux/filter/selectors";
import { getPizzas } from "../redux/pizzas/asyncActions";
import { SortType } from "../redux/filter/types";
import { setFilters } from "../redux/filter/slice";

const Home: React.FC = () => {
  const navigate = useNavigate();

  const { selectedPage, categoryId, sortObj, searchValue } =
    useSelector(filterSelector);

  const { items, isLoading } = useSelector((state: RootState) => state.pizzas);
  const dispatch = useAppDispatch();

  const fetch = React.useRef(false);
  const isFirstLoading = React.useRef(true);

  const fetchPizza = (url: string) => {
    dispatch(getPizzas(url));
  };

  //----------------------------------------------------------------//

  React.useEffect(() => {
    if (window.location.search) {
      const params = qs.parse(window.location.search.substring(1));

      const sortObjFound = list.find((obj: SortType) => {
        return obj.id === Number(params.sortId);
      });

      if (sortObjFound) {
        dispatch(
          setFilters({
            categoryId: params.categoryId ? Number(params.categoryId) : 0,
            selectedPage: params.selectedPage ? Number(params.selectedPage) : 1,
            searchValue: params.searchValue ? String(params.searchValue) : "",
            sortObj: sortObjFound,
          })
        );
      }

      fetch.current = true;
    }
  }, []);

  //----------------------------------------------------------------//

  React.useEffect(() => {
    if (!fetch.current) {
      let url =
        searchValue.length > 0
          ? `https://64ba69c35e0670a501d62006.mockapi.io/items?filter=${searchValue}`
          : `https://64ba69c35e0670a501d62006.mockapi.io/items?page=${selectedPage}&limit=4&category=${
              categoryId > 0 ? categoryId : ""
            }&sortBy=${sortObj.querry}&order=desc`;

      // axios.get(url).then((res) => {
      //   setIsLoading(false);
      //   setItems(res.data);
      // });
      fetchPizza(url);
    }

    fetch.current = false;
  }, [categoryId, sortObj, searchValue, selectedPage]);

  //----------------------------------------------------------------//

  React.useEffect(() => {
    if (!isFirstLoading.current) {
      const queryString = qs.stringify({
        categoryId,
        sortId: sortObj.id,
        selectedPage,
        searchValue,
      });

      navigate(`?${queryString}`);
    }

    isFirstLoading.current = false;
  }, [categoryId, sortObj, selectedPage]);

  //----------------------------------------------------------------//

  let pizzas = items.map((obj: any, i: any) => {
    return (
      <PizzaBlock
        {...obj}
        key={i}
        // id={obj.id}
        // name={obj.name}
        // price={obj.price}
        // imageUrl={obj.imageUrl}
        // sizes={obj.sizes}
        // types={obj.types}
      />
    );
  });

  // let pizzas = items
  //   .filter((obj, i) => {
  //     return obj.name
  //       .toLocaleLowerCase()
  //       .includes(searchValue.toLocaleLowerCase());
  //   })
  //   .map((obj, i) => {
  //     return (
  //       <PizzaBlock
  //         name={obj.name}
  //         price={obj.price}
  //         imageUrl={obj.imageUrl}
  //         sizes={obj.sizes}
  //         types={obj.types}
  //         key={i}
  //       />
  //     );
  //   });

  return (
    <div className="content">
      <div className="container">
        <div className="content__top">
          <Categories />
          <Sort />
        </div>
        <h2 className="content__title">All Pizza</h2>
        <div className="content__items">
          {isLoading
            ? [...new Array(4)].map((v, i) => {
                return <PizzaBlockSkeleton key={i} />;
              })
            : pizzas}
        </div>
        <Pagination />
      </div>
    </div>
  );
};

export default Home;
