import React from "react";

import styles from "./NotFoundBlock.module.scss";

const NotFoundBlock: React.FC = () => {
  return (
    <div className={styles.root}>
      <h1>There was nothing found</h1>
      <div className={styles.description}>
        Sorry but there is nothing found on this webpage
      </div>
    </div>
  );
};

export default NotFoundBlock;
